export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr erfahren"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anfragen"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis auf Anfrage"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieten"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu vermieten"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu verkaufen"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommerzielle Immobilie"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halle"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksart"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftlicher Grund"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugrund"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbegrundstück"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilie"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenhaus"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        }
      }
    }
  })
}
