<template>
    <div class="emma-popup-images">
        <div class="emma-popup-images__wrapper">
            <div class="emma-popup-images__head" v-on:click="$emit('close-popup')">
                <span class="emma-icon-arrow-back"></span>
                <span>{{ $t('back_to_detail_view') }}</span>
            </div>
            <div class="emma-popup-images__body">
                <div class="emma-popup-images__images">
                    <div class="emma-popup-images__image" v-for="(image, index) in images" v-bind:key="image.uuid" v-on:click="currentIndex = index">
                        <img
                                v-bind:src="'https://' + apiHost + image.fileLink + '?size=fhd&aspect_ratio=16x9'"/>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <vue-easy-lightbox
        v-bind:visible="currentIndex !== false"
        v-bind:imgs="fileLinks" v-bind:index="currentIndex"
        v-bind:rotate-disabled="true"

        v-on:hide="currentIndex = false"
    ></vue-easy-lightbox>

</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import {mapState} from "vuex";

export default {
    name: "popup-images",
    components: {VueEasyLightbox},
    data() {
        return {
            currentIndex: false
        }
    },
    props: {
        images: {
            type: Array
        }
    },
    computed: {
      ...mapState([
        'token', 'channel', 'summary', 'apiHost'
      ]),
        fileLinks() {
            return this.images.map(x => 'https://' + this.apiHost + x.fileLink + '?size=fhd&aspect_ratio=16x9');
        }
    }
}
</script>

<style scoped lang="scss">

.emma-popup-images {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
    background-color: #ffffff;

  &__wrapper {
    width: 76em;
    display: flex;
    flex-direction: column;
  }

  &__body {
    flex: 1;
      overflow: auto;
  }

  &__head {
    padding: 3em 0 3em;
    display: flex;
    align-items: center;

    &:hover {
      :nth-child(2) {
        text-decoration: underline;
      }

      cursor: pointer;
    }


    > {
      :nth-child(1) {
        margin-right: 1em;

        &::before {
          font-size: 2.4em;
        }
      }

      :nth-child(2) {
        font-size: 1.4em;
      }
    }
  }
}
.emma-popup-images__image {
    aspect-ratio: 16 / 9;
    width: 100%;
    img {
        width: 100%;
        display: block;
        cursor: pointer;
        aspect-ratio: 16 / 9;
        background-color: gainsboro;
    }

    &:nth-child(3n-1), &:nth-child(3n) {
        width: calc(50% - 0.5em);
    }
}

.emma-popup-images__images {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

@media screen and (max-width: 480px) {
  .emma-popup-images__head > :nth-child(1)::before {
    font-size: 2em;
  }
  .emma-popup-images__head {
    padding: 5em 3.5em 2em;
  }

  .emma-popup-images__body {
    padding: 2em;
  }
}
</style>
<i18n>
{
  "de": {
    "back_to_detail_view": "Zurück zur Detailansicht"
  }
}
</i18n>