<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "real-estate-item",
  emits: ['select-item'],
  props: {
    item: {}
  },
  computed: {
    ...mapState([
      'token', 'channel', 'summary', 'apiHost'
    ]),
    product() {
      return this.item.realEstate;
    },
    icons() {
      let icons = [];

      if (this.product.rooms)
        icons.push({
          icon: 'emma-icon-office-table',
          value: this.product.rooms
        });

      if (this.product.bedrooms)
        icons.push({
          icon: 'emma-icon-bed',
          value: this.product.bedrooms
        });

      if (this.product.bathrooms)
        icons.push({
          icon: 'emma-icon-bath',
          value: this.product.bathrooms
        })

      if (this.product.commercialSquareMeters)
        icons.push({
          icon: 'emma-icon-area',
          value: this.formatArea(this.product.commercialSquareMeters)  + ' m²'
        })


      return icons;
    },
    geoLocation() {
      let lang = this.$i18n.locale.toUpperCase();
      let geolocation = this.product.geoLocation.localizations?.find((x) => (x.language === lang)) || this.product.geoLocation.localizations?.find((x) => (x.language === 'EN')) || this.product.geoLocation.localizations[0];

      return geolocation.municipality + ", " + (geolocation.province || geolocation.district || geolocation.region);
    }
  },
  methods: {
    formatArea(area) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 0, minimumFractionDigits: 0}).format(area);
    },
    formatCurrency(price) {
      return new Intl.NumberFormat('de-DE', {maximumFractionDigits: 2, minimumFractionDigits: 0}).format(price);
    }
  }
})
</script>

<template>
  <div class="emma-real-estate-item">
    <div class="emma-real-estate-item__head">
      <div class="emma-real-estate-item__pricing">
        <span>{{ product.pricing[0]['@type'] === 'RealEstateRentPrice' ? $t('rent') : $t('purchase') }}</span>
      </div>

      <div class="emma-real-estate-item__image">
        <img
            v-bind:src="'https://' + this.apiHost + product.images[0].fileLink + '?size=thumbnail-small&aspect_ratio=4x3'"/>
      </div>

      <div class="emma-real-estate-item__location">
        <span class="emma-icon emma-icon-location"></span>
        <span>{{ geoLocation }}</span>
      </div>

    </div>

    <div class="emma-real-estate-item__body">
      <div class="emma-real-estate-item__title">
                <span>{{
                    $t(product.pricing[0]['@type'] === 'RealEstateRentPrice' ? 'object_rent' : 'object_buy', [$t('estate_type.' + (product['@id'].split('/')[3] !== 'land' ? product['@id'].split('/')[3] : 'usage') + '_type.' + (product.residentialRealEstateType || product.commercialRealEstateType || product.usageType))])
                  }}</span>
      </div>

      <div class="emma-real-estate-item__properties">

        <div class="emma-real-estate-item__property" v-for="icon in icons" v-bind:key="icon.value">
          <div v-bind:class="['emma-icon', icon.icon]"></div>
          <div>
            <span>{{ icon.value }}</span>
          </div>
        </div>

      </div>

      <div class="emma-real-estate-item__price">
        <span v-if="product.pricing && product.pricing[0] && product.pricing[0].price">{{
            formatCurrency(product.pricing[0].price)
          }} €</span>
        <span v-else>{{ $t('on_request') }}</span>
      </div>

      <div class="emma-real-estate-item__buttons">
        <button class="emma-real-estate-item__button" v-on:click="$emit('select-item')">
          <span>{{ $t('learn_more') }}</span>
        </button>
        <button class="emma-real-estate-item__button" v-on:click="$emit('select-item')">
          <span>{{ $t('request') }}</span>
        </button>
      </div>

    </div>
  </div>

</template>

<style scoped lang="scss">
.emma-real-estate-item {
  overflow: hidden;
  flex-direction: column;
  display: flex;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.emma-real-estate-item__head {
  position: relative;
  border-radius: 1.5em;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
}

.emma-real-estate-item__image {
  img {
    width: 100%;
    display: block;
  }

  aspect-ratio: 4 / 3;
  padding-bottom: 1.9em;
}

.emma-real-estate-item__location {
  .emma-icon {
    margin-right: 0.5em;

    &::before {
      font-size: 2em;
    }
  }

  > :nth-child(2) {
    font-size: 1.4em;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: bold;
  }

  height: 4.2em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  background-color: var(--color-highlighted);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 1em;
}

.emma-real-estate-item__pricing {
  span {
    font-size: 1.4em;
  }

  position: absolute;
  right: 2em;
  width: 15em;
  text-align: center;
  height: 2.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: var(--color-dark);
  color: white;
  letter-spacing: 0.5px;
}

.emma-real-estate-item__properties {
  display: flex;
  justify-content: space-around;
  margin-bottom: auto;
  padding-bottom: 2em;
}

.emma-real-estate-item__property {
  .emma-icon {
    font-size: 4.4em;
    text-align: center;
  }

  :nth-child(2) {
    span {
      font-size: 1.6em;
    }

    margin-top: 0.4em;
    text-align: center;
  }
}

.emma-real-estate-item__title {
  span {
    font-size: 1.8em;
  }

  padding: 2.6em 0 2.8em;
  text-align: center;
}

.emma-real-estate-item__price {
  padding: 1.5em;
  border-top: 1px solid var(--border-color);

  span {
    font-size: 1.8em;
    font-weight: bold;
  }
}

.emma-real-estate-item__button {
  all: unset;
  border: 1px solid transparent;
  border-radius: 1.2em;
  height: 3.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-color);
  color: var(--button-color-text);
  transition: background-color 0.3s;
  font-family: inherit;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1em;

  &:hover {
    background-color: color-mix(in srgb, var(--button-color), black 10%);
  }

  span {
    font-size: 1.4em;
  }
}

</style>

<i18n>
{
  "de": {
    "learn_more": "mehr erfahren",
    "request": "anfragen",
    "on_request": "Preis auf Anfrage",
    "rent": "Mieten",
    "purchase": "Kauf",
    "object_rent": "{0} zu vermieten",
    "object_buy": "{0} zu verkaufen",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Kommerzielle Immobilie",
        "bar": "Bar",
        "commercial_building": "Gebäude",
        "business": "Geschäft",
        "hall": "Halle",
        "hotel": "Hotel",
        "nd": "Egal",
        "office": "Büro",
        "operation": "Betrieb",
        "other": "Anderes",
        "restaurant": "Restaurant",
        "warehouse": "Magazin"
      },
      "usage_type": {
        "category": "Grundstücksart",
        "agricultural_land": "Landwirtschaftlicher Grund",
        "building_land": "Baugrund",
        "commercial_land": "Gewerbegrundstück",
        "land": "Grundstück"
      },
      "residential_real_estate_type": {
        "category": "Wohnimmobilie",
        "apartment": "Wohnung",
        "residential_building": "Gebäude",
        "farm": "Hof",
        "garage": "Garage",
        "house": "Haus",
        "nd": "Egal",
        "other": "Anderes",
        "row_house": "Reihenhaus",
        "villa": "Villa"
      },
      "unimportant": "Egal / Alle",
      "unknown": "Unbekannt / Nicht Angegeben"
    }
  }
}
</i18n>