export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "back_to_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])},
        "non_binding_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverbindlich Anfragen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr erfahren"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anfragen"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis auf Anfrage"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieten"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu vermieten"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu verkaufen"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommerzielle Immobilie"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halle"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksart"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftlicher Grund"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugrund"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbegrundstück"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilie"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenhaus"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "object_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektinformationen"])},
        "reference_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ref Nr. ", _interpolate(_list(0))])},
        "all_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bilder"])},
        "list": {
          "commercial_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsfläche"])},
          "net_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettofläche"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
          "rooms_bath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badezimmer"])},
          "rooms_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlafzimmer"])},
          "year_built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
          "heating_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizungskontrolle"])},
          "heating_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizungsart"])},
          "condominium_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondominumskosten"])},
          "has_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Garage"])},
          "has_car_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Stellplatz"])},
          "has_cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Keller"])},
          "has_balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Balkon"])},
          "has_terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Terasse"])},
          "has_garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Garten"])},
          "has_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Schwimmbad"])},
          "has_elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Aufzug"])},
          "is_furnished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung"])},
          "is_rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist vermietet"])},
          "energy_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energiezertifikat"])},
          "floor_meadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiesenfläche"])},
          "floor_forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldfläche"])},
          "reference_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenznummer"])}
        }
      }
    }
  })
}
