import {createStore} from "vuex";

export default createStore({
    state: {
        summary: {},
        token: "",
        channel: "",
        request: "",
        apiHost: ""
    },
    mutations: {
        setSummary(state, data) {
            state.summary = data;
        },
        setAuthData(state, data) {
            state.token = data.token;
            state.channel = data.channel;
            state.request = data.request;
            state.apiHost = data.apiHost;
        },
    },
    actions: {},
    modules: {}
});