<template>
  <div class="emma-field">
    <div class="emma-field__label">
      <span>{{ $t('location') }}</span>
    </div>
    <div class="emma-field__body">
      <div class="emma-field__wrapper">
        <input class="emma-field-location__input" v-model="searchString">
        <div class="emma-field-location__search">
          <span class="emma-icon emma-icon-search-location"></span>
        </div>
      </div>
    </div>
    <div class="emma-location-search__result-position">
      <div class="emma-location-search__result">
        <div class="emma-location-search__result-item" v-for="option in foundOptions"  v-on:click="selectLocation(option)" v-bind:key="option.uuid">
          <span>{{ buildLocationString(option.localizations) }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'
import axios from "axios";
import {mapState} from "vuex";

export default defineComponent({
  name: "field-location-search",
  data() {
    return {
      searchString: "",
      foundOptions: []
    }
  },
  props: {
    modelValue: {}
  },
  watch: {
    searchString() {
      this.searchGeoLocation();
    }
  },
  computed: {
    ...mapState([
      'token', 'channel', 'summary', 'apiHost'
    ]),
  },
  methods: {
    selectLocation(location) {
      this.foundOptions = [];
      this.$emit('update:model-value', location);
    },
    async searchGeoLocation() {
      let response = await axios.get(`https://${this.apiHost}/api/v1/channels/${this.channel}/geo_locations`, {
        params: {
          geo_location: this.searchString
        },
        headers: {
          'authorization': 'Bearer ' + this.token
        }
      });

      this.foundOptions = response.data['hydra:member'];
    },
    buildLocationString(localizations) {
      let lang = this.$i18n.locale.toUpperCase();
      let geolocation = localizations?.find((x) => (x.language === lang)) || localizations?.find((x) => (x.language === 'EN')) || localizations[0];

      return geolocation.municipality;
    }
  }
})
</script>

<style scoped lang="scss">
.emma-field-location {
  &__search {
    margin-left: auto;
    margin-right: 1.5em;

    .emma-icon {
      font-size: 2em;
    }
  }
}

.emma-location-search__result {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  box-shadow: 0 2px 2px #0000001f;
  z-index: 5;
}

.emma-location-search__result-item {
  span {
    font-size: 1.4em;
  }

  padding: 0.5em 2em 0.5em;
  transition: background-color 0.3s;
  cursor: pointer;

  &:first-child {
    padding-top: 1em;
  }

  &:hover {
    background-color: color-mix(in srgb, white, gray 10%);
  }

  &:last-child {
    padding-bottom: 1em;
  }
}

.emma-location-search__result-position {
  position: relative;
}

</style>

<i18n>
{
  "de": {
    "location": "ORT"
  },
  "en": {
    "location": "ORT"
  }
}
</i18n>