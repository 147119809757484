<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "button-big"
})
</script>

<template>
  <div class="emma-button-big">
    <div class="emma-button-big__wrapper">
      <button class="emma-button-big__button">
        <span>{{ $t('search') }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.emma-button-big {
  &__button {
    all: unset;
    border: 1px solid transparent;
    border-radius: 1.2em;
    height: 3.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-color);
    color: var(--button-color-text);
    transition: background-color 0.3s;
    font-family: inherit;
    cursor: pointer;
    width: 100%;


    &:hover {
      background-color: color-mix(in srgb, var(--button-color), black 10%);
    }
     span {
       font-size: 1.4em;
       font-weight: bold;
       text-transform: uppercase;
     }
  }
}
</style>

<i18n>
{
  "de": {
    "search": "Suchen"
  }
}
</i18n>