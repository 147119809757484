export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich aktzeptiere die Datenschutzerklärung"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragen"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider gab es einen Fehler beim Senden deiner Anfrage. Gerne kannst du uns Telefonisch oder per E-Mail kontaktieren."])},
        "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage wird gesendet..."])},
        "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Anfrage ist erfolgreich versendet worden!"])}
      }
    }
  })
}
