import { createApp } from 'vue'
import App from './App.vue'
import {createI18n} from "vue-i18n";
import store from "@/store";
const lang = createI18n({
    locale: 'de',
    fallbackLocale: 'en',
});

store.commit('setAuthData', {
    token: document.querySelector('#emma-widget').getAttribute('data-token'),
    channel: document.querySelector('#emma-widget').getAttribute('data-channel'),
    request: document.querySelector('#emma-widget').getAttribute('data-request'),
    apiHost: document.querySelector('#emma-widget').getAttribute('data-host') ?? 'api.emma-office.net',
});

createApp(App).use(lang).use(store).mount('#emma-widget')