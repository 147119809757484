<template>
  <div class="emma-field">

    <button
        class="emma-field__wrapper emma-choice__wrapper"
        v-bind:class="{'emma-choice--select-2': options && options[1] && this.modelValue === options[1].value}" v-on:click="toggleChoice()">
      <div class="emma-choice__button" v-for="option in options" v-bind:key="option.value">
        <span>{{ option.label }}</span>
      </div>
    </button>

  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "field-choice",
  props: {
    modelValue: {

    },
    options: {

    }
  },
  methods: {
    toggleChoice() {
      if(this.modelValue === this.options[0].value) {
        this.$emit('update:model-value', this.options[1].value);
      } else {
        this.$emit('update:model-value', this.options[0].value);
      }
    }
  },
  mounted() {
    if(!this.modelValue && this.options && this.options[0])
      this.$emit('update:model-value', this.options[0].value);
  }
})
</script>

<style scoped lang="scss">
.emma-choice--select-2 {
  &.emma-choice__wrapper::before {
    left: 50%;
  }
}

.emma-choice {
  &__wrapper {
    display: flex;
    align-items: center;
    font-size: inherit;
    box-sizing: content-box;
    padding: 0;
    width: 100%;
    font-family: inherit;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      border-radius: 1.2em;
      z-index: 5;
      background-color: var(--button-item-selected);
      transition: left 0.3s;
    }
  }

  &__button {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;

    span {
      font-size: 1.4em;
      font-weight: bold;
    }

    &--selected {
      border-right: 1px solid var(--border-color);
      border-radius: 1.2em;
      background-color: var(--button-item-selected);

    }
  }
}
</style>