<template>
  <div class="emma-request__wrapper">
    <div class="emma-request__head">
      <field-text v-bind:placeholder="$t('first_name')" v-model="firstName" v-bind:error="errors.firstName"/>
      <field-text v-bind:placeholder="$t('last_name')" v-model="lastName" v-bind:error="errors.lastName"/>
      <field-text v-bind:placeholder="$t('phone')" v-model="phone" v-bind:error="errors.phone"/>
      <field-text v-bind:placeholder="$t('email')" v-model="email" v-bind:error="errors.email"/>
    </div>
    <div class="emma-request__comment">
      <field-textarea v-bind:placeholder="$t('comment')" v-model="comment" v-bind:error="errors.comment"/>
    </div>
    <div class="emma-request__send">
      <field-checkbox v-model="privacy" v-bind:label="$t('privacy')" v-bind:error="errors.privacy"/>
      <div class="emma-request__button-wrapper">
        <button class="emma-request__button" v-on:click="send()">
          <span>{{ $t('request') }}</span>
        </button>
      </div>

    </div>

    <div class="emma-request__overlay" v-if="error || sending || status > 0">
      <div class="emma-request__overlay-container">
        <span v-if="error" class="emma-request__overlay-text--error">
          {{ $t('error') }}
        </span>
        <span v-else-if="sending" class="emma-request__overlay-text--sending">
          {{ $t('sending') }}
        </span>
        <span v-else-if="status === 1">
          {{ $t('sent') }}
        </span>
      </div>
    </div>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import FieldText from "@/components/field-text.vue";
import FieldTextarea from "@/components/field-textarea.vue";
import FieldCheckbox from "@/components/field-checkbox.vue";
import axios from "axios";
import {mapState} from "vuex";

export default defineComponent({
  name: "request-form",
  components: {FieldCheckbox, FieldTextarea, FieldText},
  props: {
    product: {}
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      comment: "",
      privacy: "",
      sending: false,

      error: false,
      status: 0,

      errors: {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        privacy: false,
        comment: false
      }
    }
  },
  computed: {
    ...mapState([
      'token', 'channel', 'request', 'summary', 'apiHost'
    ]),
    valueString() {
      return this.firstName +
          this.lastName +
          this.phone +
          this.email +
          this.comment +
          this.privacy;
    }

  },
  methods: {
    checkErrors() {
      this.errors = {
        firstName: !(this.firstName.length > 2),
        lastName: !(this.lastName.length > 2),
        // eslint-disable-next-line no-control-regex
        email: !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(this.email),
        phone: !(this.phone.length > 5),
        privacy: !this.privacy,
        comment: false
      }
    },
    async send() {
      this.checkErrors();
      if (!Object.values(this.errors).find(x => x === true)) {
        this.sending = true;
        await axios.post(`https://${this.apiHost}/api/v1/request_channels/${this.request}/request`, {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          comment: this.comment,
          estate: this.product,
          language: navigator.language.substring(0, 2),
        }, {
          headers: {
            'authorization': 'Bearer ' + this.token
          }
        }).then(() => {
          this.status = 1;
        }).catch(() => {
          this.error = true;
        })
        this.sending = false;
      }
    }
  },
  watch: {
    valueString() {
      this.errors = {
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        privacy: false,
        comment: false
      }
    }
  }
})
</script>


<style scoped lang="scss">
.emma-request__head {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.2em;
  row-gap: 0.8em;
}

.emma-request__comment {
  margin-top: 0.8em;
}

.emma-request__send {
  margin-top: 2em;
  gap: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emma-request__button {
  all: unset;
  border: 1px solid transparent;
  border-radius: 1.2em;
  height: 3.6em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-color);
  color: var(--button-color-text);
  transition: background-color 0.3s;
  font-family: inherit;
  cursor: pointer;
  padding: 0 3em;

  &:hover {
    background-color: color-mix(in srgb, var(--button-color), black 10%);
  }

  span {
    font-size: 1.4em;
    font-weight: bold;
  }
}

.emma-request__wrapper {
  position: relative;
}

.emma-request__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emma-request__overlay-container {
  span {
    font-size: 1.6em;
    color: #1B5E20;

    &.emma-request__overlay-text--sending {
      color: #FF8F00;
    }
    &.emma-request__overlay-text--error {
      color: #BF360C;
    }

  }

  padding: 3em;
}


@media screen and (max-width: 480px) {
  .emma-request__head {
    grid-template-columns: 1fr;
  }
  .emma-request__button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2em;
  }

}
</style>

<i18n>
{
  "de": {
    "first_name": "Vorname",
    "last_name": "Nachname",
    "phone": "Telefonnummer",
    "email": "E-Mail",
    "privacy": "Ich aktzeptiere die Datenschutzerklärung",
    "comment": "Kommentar",
    "request": "Anfragen",
    "error": "Leider gab es einen Fehler beim Senden deiner Anfrage. Gerne kannst du uns Telefonisch oder per E-Mail kontaktieren.",
    "sending": "Anfrage wird gesendet...",
    "sent": "Deine Anfrage ist erfolgreich versendet worden!"
  }
}
</i18n>